@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/variables-dark";
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

//color scheme ref https://www.canva.com/colors/color-meanings/purple/

$default-primary-color: #a020f0;

$font-family-base: "Noto Sans Thai";
//$primary: #2592e0;
$primary: $default-primary-color;
$default: #fff;
$info: #e3ebf7;
//$danger: #FF5959;
$danger: #ff0a00;
//$success: #019d2c;
$success: #13ce66;
$secondary: rgb(0 0 0 / 50%);
//$warning: #e0a225;

$theme-colors: map-merge($theme-colors, (
  "primary": $primary,
  "info": $info,
  "default": $default,
  "danger": $danger,
  "success": $success
));

$body-bg: #ffffff;

$input-border-color: #ccc;

$popover-max-width: 500px;

@import "bootstrap";
